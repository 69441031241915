import { Box, FluidGrid, FluidGridAspectRatio, Skeleton, Typography } from '@deltasierra/react/components/core';
import { WatchElementRefType } from '@deltasierra/react/hooks/common';
import { Image03 } from '@deltasierra/react/icons';
import { useMemo } from 'react';
import {
    AssetLibraryBrowserGridBreadcrumbs,
    AssetLibraryBrowserGridBreadcrumbsProps,
} from './AssetLibraryBrowserGridBreadcrumbs';
import { AssetLibraryBrowserGridItem } from './AssetLibraryBrowserGridItem';
import { AssetLibraryThumbnailFolderContainerProps } from './AssetLibraryThumbnailFolderContainer';
import { ModalAsset } from './contexts';

const loadingSkeletons = Array(13)
    .fill(0)
    .map((_, id) => ({
        id,
    })) as unknown as ModalAsset[];

export type AssetLibraryBrowserGridProps = Pick<
    AssetLibraryBrowserGridBreadcrumbsProps,
    'collectionTitle' | 'currentFolders' | 'onClickBreadcrumb'
> &
    Pick<AssetLibraryThumbnailFolderContainerProps, 'folderActions' | 't'> & {
        allowedTypes?: string[];
        assets: ModalAsset[];
        isLoadingAssets: boolean;
        isLoadingCollections: boolean;
        maxFileSize: number;
        onClickThumbnail: (asset: ModalAsset) => void;
        watchElementRef: WatchElementRefType | false;
    };

// eslint-disable-next-line max-lines-per-function
export const AssetLibraryBrowserGrid = ({
    allowedTypes,
    assets,
    collectionTitle,
    currentFolders,
    folderActions,
    isLoadingAssets,
    isLoadingCollections,
    maxFileSize,
    onClickBreadcrumb,
    onClickThumbnail,
    t,
    watchElementRef,
}: AssetLibraryBrowserGridProps): JSX.Element => {
    const dataFixed = useMemo(() => {
        const items = isLoadingAssets ? loadingSkeletons : assets;

        return {
            allowedTypes,
            folderActions,
            isLoadingAssets,
            items,
            maxFileSize,
            onClickThumbnail,
            t,
        };
    }, [allowedTypes, folderActions, isLoadingAssets, assets, maxFileSize, onClickThumbnail, t]);

    const aspectRatios = useMemo(
        () =>
            dataFixed.items.map(
                (asset): FluidGridAspectRatio<string> =>
                    [
                        asset.id,
                        asset.__typename === 'AssetFile' && asset.thumbnails?.medium
                            ? {
                                  height: asset.thumbnails.medium.height,
                                  width: asset.thumbnails.medium.width,
                              }
                            : { height: 256, width: 256 },
                    ] as const,
            ),
        [dataFixed],
    );

    return (
        <Box
            sx={{
                alignContent: 'flex-start',
                backgroundColor: 'common.white',
                borderRadius: '8px',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '1.25em',
                justifyContent: 'flex-start',
                padding: '1rem',
            }}
        >
            <AssetLibraryBrowserGridBreadcrumbs
                collectionTitle={collectionTitle}
                currentFolders={currentFolders}
                isLoadingCollections={isLoadingCollections}
                t={t}
                onClickBreadcrumb={onClickBreadcrumb}
            />
            {!isLoadingAssets && !isLoadingCollections && assets.length < 1 ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '80%',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        color="primary.dark"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            path: { strokeWidth: '1' },
                        }}
                    >
                        <Image03 height="3.25rem" width="3.25rem" />
                    </Box>
                    <Typography color="common.black" variant="h3">
                        {t('No assets found')}
                    </Typography>
                </Box>
            ) : (
                <FluidGrid
                    aspectRatios={aspectRatios}
                    data={dataFixed}
                    dataCy="asset-library-thumbnail-grid"
                    itemMinWidth={75}
                    rowMaxHeight={300}
                    rowMinHeight={150}
                    skeleton={
                        watchElementRef && (
                            <Skeleton
                                height={256}
                                ref={watchElementRef('skeleton')}
                                variant="rectangular"
                                width="100%"
                            />
                        )
                    }
                    spacing={10}
                >
                    {AssetLibraryBrowserGridItem}
                </FluidGrid>
            )}
        </Box>
    );
};

AssetLibraryBrowserGrid.displayName = 'AssetLibraryBrowserGrid';
